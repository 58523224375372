import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function About() {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
        <div className="lg:max-w-lg lg:self-end">
          <section aria-labelledby="information-heading" className="mt-4">
            <div className="lg:py-24 xl:py-64  prose font-sans antialiased text-slate-600 space-y-6">
              <h2 className="text-3xl tracking-wide text-slate-600 sm:text-5xl">
                Ismel Gonzalez Pérez
              </h2>
              <h3>
                Masseur professionnel, franco-cubain. titulaire d'un diplome de
                l'Institut Supérieur des Arts à la Havane (Cuba), il a exercé la
                profession de danseur professionnel plus de 15 ans en France.
              </h3>

              <p>
                Formé et diplômé comme Praticien Bien-être dans l'école
                parisienne Azenday en 2020.
              </p>
              <p>
                Dans la continuité de sa carrière artistique, il a trouvé dans
                le "massage bien-être" un nouveau sens associé à sa passion pour
                le corps et sa complexité. C'est pour cela qu'il a décidé de se
                consacrer à temps plein à cette pratique.
              </p>
              <blockquote>
                Le massage, comme la danse, sont bénéfiques pour la santé tant
                physique que mentale.
              </blockquote>
              <p>
                Ismel vous apportera toute sa riche expérience et vous aidera à
                trouver, détente, sérénité et bien-être.
              </p>
            </div>
          </section>
        </div>

        <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div className=" rounded-lg overflow-hidden">
            <StaticImage
              src="../images/ismel0.jpg"
              loading="eager"
              className="w-full h-full object-center object-cover"
              quality={100}
              alt="Ismel Gonzalez Pérez"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
