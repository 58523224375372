import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Footer from "../components/footer";

import Massages from "../components/massages";
import About from "../components/about";

const iconinsta = {
  icon: (props) => (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        clipRule="evenodd"
      />
    </svg>
  ),
  href: "https://www.instagram.com/ismel-parislumiere",
};

const IndexPage = ({ data }) => {
  const features = data.allMarkdownRemark.nodes;
  return (
    <Layout>
      <Seo
        title="Maninsages, massages profesionnelle"
        description="La sérénité à portèe de mains, Massages Suédois, Balinais, Deep
              Tissue, Réflexologie plantaire et Massage Japonais du visage"
      />

      <main className="lg:relative bg-gradient-to-r from-cyan-500 to-cyan-800">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-40 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <div className=" space-y-5">
              <StaticImage
                src="../images/logo.svg"
                loading="eager"
                width={300}
                quality={100}
                alt="Logo Mains Sages"
              />
              <h1 className="tracking-wide font-bold text-slate-200 text-3xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                Masseur professionnel
              </h1>
              <p className="font-base tracking-tight text-gray-700 sm:text-xl">
                Ismel Gonzalez Pérez
              </p>

              <h2 className="italic text-slate-200 text-2xl my-0 sm:text-4xl mb-2">
                Prendre soin de vous
              </h2>

              <p className="mt-4 max-w-md mx-auto  text-xl text-slate-100 sm:text-xl md:mt-5 md:max-w-3xl">
                Massages Suédois - Balinais - Deep Tissue - Réflexologie
                plantaire - Massage Japonais du visage
              </p>
            </div>
            <div className="mt-8 flex mx-auto  flex-col lg:mx-0 space-y-4  text-white text-sm font-bold leading-6 max-w-xs">
              <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                <PhoneIcon
                  className="flex-shrink-0 h-6 w-6 text-slate-200"
                  aria-hidden="true"
                />
                <span className="ml-3 text-slate-200 no-underline">
                  <a href="tel=+33 6 27 86 86 76"> +33 6 27 86 86 76 </a>
                </span>
              </div>
              <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                <MailIcon
                  className="flex-shrink-0 h-6 w-6 text-slate-200"
                  aria-hidden="true"
                />
                <a
                  href="mailto:isgonzperez@gmail.com"
                  className="ml-3 no-underline hover:text-slate-500 text-slate-200"
                >
                  isgonzperez@gmail.com
                </a>
              </div>
              <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                <iconinsta.icon
                  className="h-6 w-6 text-slate-200"
                  aria-hidden="true"
                />
                <a
                  href="https://www.instagram.com/ismel-parislumier"
                  className="ml-3 no-underline hover:text-slate-500 text-slate-200"
                >
                  ismel-parislumiere
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-96 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <StaticImage
            src="../images/ismel.jpg"
            loading="eager"
            className="absolute inset-0 w-full h-full object-cover"
            quality={100}
            alt="mains sages"
          />
        </div>
      </main>
      <About />

      <Massages features={features} />

      <Footer />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allMarkdownRemark {
      nodes {
        id
        html
        frontmatter {
          title
          price
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
