import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Massages({ features }) {
  return (
    <div className="bg-slate-50">
      <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
        <div className="mt-4">
          <h2 className="text-3xl tracking-wide text-slate-600 sm:text-5xl">
            Massages
          </h2>
        </div>

        <div className="mt-16 space-y-16">
          {features.map((feature, featureIdx) => {
            const images = getImage(feature.frontmatter.featuredImage);
            return (
              <div
                key={feature.id}
                className="font-sans antialiased  flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? "lg:col-start-1" : "lg:col-start-8 ",
                    "mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 "
                  )}
                >
                  <h3 className="text-3xl mb-4 font-normal text-slate-900">
                    {feature.frontmatter.title}
                  </h3>

                  <section
                    className="text-base text-slate-600"
                    dangerouslySetInnerHTML={{ __html: feature.html }}
                    itemProp="articleBody"
                  />
                  <p className=" mt-4 text-sm text-slate-500">
                    {feature.frontmatter.price}
                  </p>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? "lg:col-start-7" : "lg:col-start-1",
                    "flex-auto lg:row-start-1 lg:col-span-6 "
                  )}
                >
                  <div>
                    <GatsbyImage
                      image={images}
                      alt={feature.frontmatter.title}
                      imgClassName="w-full h-full object-center object-cover"
                      className="aspect-w-1 aspect-h-1  rounded-lg bg-gray-100 overflow-hidden"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
