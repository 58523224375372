import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
const iconinsta = {
  icon: (props) => (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        clipRule="evenodd"
      />
    </svg>
  ),
  href: "https://www.instagram.com/ismel-parislumiere",
};

export default function Footer() {
  return (
    <footer className="bg-gradient-to-r from-cyan-500 to-cyan-800">
      <div>
        <div className="relative">
          <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <StaticImage
              src="../images/ismelhands.jpg"
              loading="eager"
              className="w-full h-full object-cover"
              quality={100}
              alt="mains sages"
            />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <p className="mt-2 text-white text-center sm:text-left text-3xl font-normal tracking-wide sm:text-4xl">
                Contact
              </p>
              <p className="mt-3 text-center sm:text-left text-lg text-slate-100">
                Ismel Gonzalez Pérez
              </p>
              <div className="mt-8 flex mx-auto  flex-col lg:mx-0 space-y-4  text-white text-base  tracking-wide  max-w-xs">
                <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-slate-100"
                    aria-hidden="true"
                  />
                  <span className="ml-3 hover:text-white text-slate-100 no-underline">
                    <a href="tel=+33 6 27 86 86 76"> +33 6 27 86 86 76 </a>
                  </span>
                </div>
                <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-slate-200"
                    aria-hidden="true"
                  />
                  <a
                    href="mailto:isgonzperez@gmail.com"
                    className="ml-3 no-underline hover:text-white text-slate-100"
                  >
                    isgonzperez@gmail.com
                  </a>
                </div>
                <div className=" rounded-lg flex sm:items-center  justify-center lg:justify-start ">
                  <iconinsta.icon
                    className="h-6 w-6 text-slate-200"
                    aria-hidden="true"
                  />
                  <a
                    href="https://www.instagram.com/ismel-parislumier"
                    className="ml-3 no-underline hover:text-white text-slate-100"
                  >
                    ismel-parislumiere
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-slate-50">
        <div className="max-w-7xl mx-auto py-4 px-4 overflow-hidden sm:px-6 lg:px-8">
          <p className="my-8 text-center text-sm text-gray-400">
            {" "}
            © {new Date().getFullYear()} &middot; mainsages - Ismel Gonzalez
            Pérez
          </p>
        </div>
      </div>
    </footer>
  );
}
